<template>
  <div>
    <div class="title" v-if="flag == 1"> 
      <!-- 查询 -->
      <el-row :gutter="4" style="padding: 10px 0 0 10px">
        <select-input  :options="options1" @selectSearchChange="selectSearchChange" @searchList="onSubmit"></select-input>
        <!-- <el-col>
          <el-select style="width: 136px; margin-right: 5px" size="small" placeholder="敏感词库" v-model="queryData.name" @change="searchTypeChange">
            <el-option v-for="item in options1" :key="item.value1" :label="item.label1" :value="item.label1">
            </el-option>
          </el-select>
          <el-input size="small" style="width: 200px; margin-right: 5px" v-model.trim="queryData.input" :placeholder="`请输入${placeholder}`" @keyup.enter.native="onSubmit"></el-input>
          <el-button type="primary" style="height: 31px" size="small" @click="onSubmit">查询</el-button>
        </el-col> -->
      </el-row>
      <el-row :gutter="4" style="padding: 0px 0 0 10px">
        <el-col :span="3">
        <el-button-group>
          <el-button type="primary" size="small" @click="add">{{$t('public.New')}} </el-button>
          <el-button style="border-radius: 0px 3px 3px 0px;margin: 0px 0px 0px 0px;margin-left: 1px;"  @click="delMuch(delarr)" :type="typeInfo" :disabled="!hasSeleted" size="small">{{$t('public.Delete')}}</el-button>
        </el-button-group>
      </el-col>
      </el-row>
      <el-row :gutter="16" class="mt15" style="margin-right: 28px">
        <el-table :row-key="getRowKeys" size="mini" stripe ref="multipleTable" :data="tableData" class="tableClass" tooltip-effect="dark" style="width: 100%; margin-top: 10px; margin-left: 18px" :default-sort="{ prop: 'updateTime', order: 'descending' }" @selection-change="handleSelectionChange" @sort-change="sort">
          <el-table-column :reserve-selection="true" header-align="center" align="center" type="selection" width="60" :selectable="selectEnable">
          </el-table-column>
          <el-table-column sortable="custom" prop="name" :label="$t('AppManage.SensitiveLexicon.sensitivenessList.LexicalName')" width="150">
            <template slot-scope="scope">
              <div>
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="words" :show-overflow-tooltip="true" :label="$t('AppManage.SensitiveLexicon.sensitivenessList.SensitiveWords')" width="150">
          </el-table-column>
          <el-table-column sortable="custom" prop="updateTime" :label="$t('public.ModificationTime') " show-overflow-tooltip width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                {{ scope.row.updateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span class="action_icon">
              <img @click="edit(row)" :title="$t('public.Editor')" src="../../assets/icon_write.png" alt="" style="width: 15px; height: 15px; cursor: pointer" />
              </span>
              <!--   v-show="
                  row.name != '贪腐词库' &&
                  row.name != '民生词库' &&
                  row.name != '反动词库' &&
                  row.name != '暴恐词库'
                " -->
                <span class="action_icon">
                  <img v-show="
                  row.name != '贪腐词库' &&
                  row.name != '民生词库' &&
                  row.name != '反动词库' &&
                  row.name != '暴恐词库' &&
                  row.id != 1 &&
                  row.id != 2 &&
                  row.id != 3 &&
                  row.id != 4
                " @click="del(row.id)" :title="$t('public.Delete')" src="../../assets/icon_del.png" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
                </span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-left: 15px">
          <pagination :page="listData.currentPage" :limit="listData.pageSize" :total="listData.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
        </div>
 
      </el-row>
    </div>
    <!-- 编辑的框 -->
    <div v-if="flag == 2">
      <navi-gation />
      <el-row :gutter="16" style="margin-top: 25px; margin-left: 60px">
        <el-col :span="6">
          <el-form size="small" :model="ruleFormEdit" :rules="rulesEdit" ref="ruleFormEdit" label-width="120px" class="demo-ruleForm">
            <el-form-item :label="$t('AppManage.SensitiveLexicon.sensitivenessList.LexicalNameContent')" prop="name">
              <el-input style="width: 250px" v-model="ruleFormEdit.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('AppManage.SensitiveLexicon.sensitivenessList.SensitiveWordsContent')" prop="words">
              <el-input style="width: 250px" :rows="4" type="textarea" v-model="ruleFormEdit.words" maxlength="2000" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="submitFormEdit('ruleFormEdit')">{{$t('public.Save')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <!-- 新增的框 -->
    <div v-if="flag == 3">
      <navi-gation />
      <el-row :gutter="16" style="margin-top: 25px; margin-left: 60px">
        <el-col :span="6">
          <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" @submit.native.prevent label-width="120px" class="demo-ruleForm">
            <el-form-item :label="$t('AppManage.SensitiveLexicon.sensitivenessList.LexicalNameContent')" prop="name">
              <el-input style="width: 250px" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('AppManage.SensitiveLexicon.sensitivenessList.SensitiveWordsContent')" prop="words">
              <el-input style="width: 250px" :rows="4" type="textarea" v-model="ruleForm.words" maxlength="2000" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="submitForm('ruleForm')">{{$t('public.Save')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <!-- 提示消息框 -->
    <dialogInfo :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import selectInput from "@/components/selectInput"
import naviGation from '@/components/hearder/index' //头部组件
import Eldialog from '@/components/elDialog' //详情弹框
import pagination from '@/components/pagination/page.vue' //分页
import dialogInfo from '@/components/promptMessage/index' //提示消息框
export default {
  components: {
    selectInput,
    naviGation,
    Eldialog,
    pagination,
    dialogInfo
  },
  data() {
    // 添加标题正则
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('不支持空格及特殊字符'))
      } else {
        callback()
      }
    }
    return {
      typeInfo: 'info',// 删除按钮默认灰色
      queryData: {
        //查询条件 （param）
        name: '词库名称',
        words: '',
        input: ''
      },
      // delarr: [], //多个删除存放数组
      flag: 1, //显示隐藏切换
      delarr: [], //存放要删除的数据
      statusVisible: false, //关联应用弹框默认不显示
      visibaelFlag: false, //提示消息弹框默认不显示
      input: '', //关联应用的查询
      btn: true, //根据id去显示是否可删除
      dataTolCount: '', //点击列表的总数
      // 遮罩全选
      flagDisable: false,
      isCheckShow: false,
      titleInfo: '',
      selcetValue: 'name',
      placeholder: '词库名称', //默认placeholder为用户名
      options1: [
        //用户名/姓名/电话切换查询下拉框
        {
          value: 'name',
          label: '词库名称'
        },
        {
          value: 'words',
          label: '敏感词'
        }
      ],
      value1: '',
      // 列表参数
      listData: {
        total: 0,
        currentPage: 1, //pageNumber
        pageSize: 10, //rowNumber
        orderColume: 'updateTime',
        orderRule: 'DESC',
        name: '',
        words: ''
        // sort: "updateTime desc",
        // where: { searchKey: "name|words", searchContent: "" },
      },
      selectVal:{
        deviceName:'',
        InputText:""
      },
      // 数据列表
      tableData: [],
      // 点击名称的列表
      tableDataNmae: [],
      // 分页
      // page: {
      //   orderColume: "updateTime",
      //   orderRule: "DESC",
      //   pageSize: 10, //每页条数,  默认10条
      //   total: 0, //总条数
      //   // totalPages: 40, //总页数
      //   currentPage: 1,
      // },
      // //新增参数
      ruleForm: {
        action: 'add',
        name: '',
        words: ''
      },
      // 新增校验
      rules: {
        name: [
          {
            required: true,
            message: '请输入词库名称',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 30,
            message: '最长输入30个字符，不支持空格及特殊字符',
            trigger: 'blur'
          },
          { validator: validcodeName, trigger: 'blur' }
        ],
        words: [
          {
            required: true,
            message: '最多支持2000个关键词，输入多个需换行分隔',
            trigger: 'blur'
          },
          { min: 1, max: 2000, message: '不能超过2000字符', trigger: 'blur' }
        ]
      },
      //编辑参数
      ruleFormEdit: {
        action: 'edit',
        name: '',
        words: ''
      },
      // 编辑校验
      rulesEdit: {
        name: [
          {
            required: true,
            message: '请输入敏感词',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 30,
            message: '最长输入30个字符，不支持空格及特殊字符',
            trigger: 'blur'
          },
          { validator: validcodeName, trigger: 'blur' }
        ],
        words: [
          {
            required: true,
            message: '最多支持2000个关键词，输入多个需换行分割',
            trigger: 'blur'
          },
          { min: 1, max: 2000, message: '不能超过2000字符', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length
    }
  },
  mounted() {
    this.tableList()
  },
  methods: {
    selectSearchChange(val){
      this.selcetValue = val.deviceName
    },
    getRowKeys(row) {
      return row.id
    },
    // 禁用
    selectEnable(row, rowIndex) {
      if (
        row.name == '贪腐词库' ||
        row.name == '民生词库' ||
        row.name == '反动词库' ||
        row.name == '暴恐词库' ||
        row.id == 1 ||
        row.id == 2 ||
        row.id == 3 ||
        row.id == 4
      ) {
        return false
      } else {
        return true
      }
    },
    // 列表
    async tableList(val) {
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        name: val?val.deviceName == "name"?val.InputText:'': "",
        words: val?val.deviceName == "words"?val.InputText:'': "",
      }
      console.log(param, 'parammmmmm')
      const res = await this.$axios.post(
        '/httpServe/sensitive_words/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content //列表数据
      this.listData.total = res.data.total //列表总数
    },
    // 新增
    async addListData() {
      this.flag = 3
      var param = {
        // action: "add",
        ids: [],
        name: this.ruleForm.name,
        words: this.ruleForm.words
      }
      const res = await this.$axios.post(
        '/httpServe/sensitive_words/save',
        param,
        true
      )
      console.log(res, 'res')
      if (res.data == -1) {
        // this.titleInfo = "词库名称已存在";
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('AppManage.SensitiveLexicon.sensitivenessList.ThesaurusNme'),
          type: 'error',
          offset: 100
        })
        this.flag = 3
      } else {
        // this.titleInfo = "保存成功";
        this.ruleForm.name = ''
        this.ruleForm.memo = ''
        this.$refs.ruleForm.resetFields()
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
        this.tableList()
        this.flag = 1
        // this.$refs.ruleForm.resetFields()   //会reset成初始值
      }
    },
    add() {
      this.flag = 3
      // this.$refs.ruleForm.form.resetFields()
    },
    // 新增校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addListData()
          // this.tableList()
        } else {
          return false
        }
      })
    },
    // 编辑
    async edit(val) {
      this.flag = 2
      this.ruleFormEdit = val
      // if(val.id == '1' || val.id =='2' || val.id =='3' val.id == '4') {
      //   del.style.display = "none"
      // }
    },
    // 编辑接口
    async save() {
      var param = {
        ids: [this.ruleFormEdit.id + ''],
        words: this.ruleFormEdit.words,
        name: this.ruleFormEdit.name
      }
      const res = await this.$axios.post(
        '/httpServe/sensitive_words/save',
        param,
        true
      )
      if (res.data == -1) {
        // this.titleInfo = "词库名称已存在";
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('AppManage.SensitiveLexicon.sensitivenessList.ThesaurusNme'),
          type: 'error',
          offset: 100
        })
        this.flag = 2
      } else {
        // this.titleInfo = "保存成功";
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
        this.flag = 1
      }
    },
    // 编辑校验
    submitFormEdit(ruleFormEdit) {
      this.$refs[ruleFormEdit].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          return false
        }
      })
    },
    // 删除
    async del(id) {
      this.id = id
      this.$confirm(this.$t('AppManage.SensitiveLexicon.sensitivenessList.DeleteSelectedThesaurus'), this.$t('public.PromptMessage') , {
        cancelButtonText: this.$t('public.Cancel'),
        confirmButtonText: this.$t('public.Verify'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var param = {
            ids: [id + '']
          }
          const res = await this.$axios.post(
            '/httpServe/sensitive_words/delete',
            param,
            true
          )
          // 删除失败，敏感词库正在被应用策略使用
          if (res.data == -1) { 
            this.$message({
              message: this.$t('AppManage.SensitiveLexicon.sensitivenessList.DelPolicyUse'),
              type: 'error',
              offset: 100
            })
          } else { 
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
            this.listData.currentPage='1'
            this.tableList()
            this.$refs.multipleTable.clearSelection()
          }
        })
        .catch(() => {
          return false
        })
    },
    //下拉选中查询类型--转译input中placeholder显示
    searchTypeChange(item) {
      this.selcetValue = item
      console.log(this.selcetValue)
      this.placeholder = item
    },
    // 查询
    async onSubmit(val) {
      this.selectVal = val
    
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        name: this.selcetValue == 'name' ? val.InputText : '',
        words: this.selcetValue == 'words' ? val.InputText : ''
      }
      console.log(param, 'parammmmmm')
      const res = await this.$axios.post(
        '/httpServe/sensitive_words/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content //列表数据
      this.listData.total = res.data.total //列表总数
      this.listData.currentPage = 1
    },
    //多条删除
    async delMuch(obj) {
      console.log(obj, '----')
      this.$confirm(this.$t('AppManage.SensitiveLexicon.sensitivenessList.DeleteSelectedThesaurus'), this.$t('public.PromptMessage') , {
        cancelButtonText: this.$t('public.Cancel'),
        confirmButtonText: this.$t('public.Verify'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var ids = []
          obj.forEach((item) => {
            ids.push(item.id)
          })
          var param = {
            ids: ids
          }
          console.log(param, 'param----删除参数')
          const res = await this.$axios.post(
            '/httpServe/sensitive_words/delete',
            param,
            true
          )
          if (res.data == -1) { 
            this.$message({
              message: this.$t('AppManage.SensitiveLexicon.sensitivenessList.DelPolicyUse'),
              type: 'error',
              offset: 100
            })
          } else { 
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
            this.listData.currentPage='1'
            this.tableList()
            this.$refs.multipleTable.clearSelection()
          } 
        })
        .catch(() => {
          return false
        })
    },
    //表格当删除发生变化
    handleSelectionChange(obj) {
      console.log(obj, '----')
      this.delarr = obj
      this.typeInfo = 'primary'
      if (this.delarr.length == 0) {
        this.typeInfo = 'info'
      }
    },
    //当用户手动勾选数据行的 Checkbox 时触发的事件
    checkChange(selection, row) {
      let arr1 = []
      selection.forEach((item) => {
        arr1.push(item.id)
        //去重
        this.delarr = [...new Set(arr1)]
      })
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.listData.currentPage = page
      this.listData.pageSize = limit
      // 调用列表
      this.tableList(this.selectVal)
    },
    // 排序
    sort(column) {
      console.log(column, '排序')
      if (column.prop == 'name') {
        if (column.order == 'ascending') {
          this.listData.orderColume = 'name'
          this.listData.orderRule = 'ASC'
        } else {
          this.listData.orderColume = 'name'
          this.listData.orderRule = 'DESC'
        }
      } else if (column.prop == 'updateTime') {
        if (column.order == 'ascending') {
          this.listData.orderColume = 'updateTime'
          this.listData.orderRule = 'ASC'
        } else {
          this.listData.orderColume = 'updateTime'
          this.listData.orderRule = 'DESC'
        }
      }
      this.tableList()
    },
    // 高度
    heightCustom() {
      this.height.height = this.num
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false //点击出现详情弹框
      this.visibaelFlag = false //提示消息弹框关闭
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    //当前页全选
    curSelection() {
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
// ::v-deep .el-form-item {
//   margin-bottom: 17px !important;
// }
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 47px;
  left: 10px;
  z-index: 999;
  // background:rgba(255, 255, 255,.1)
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;
    div {
      display: inline-block;
    }
  }
  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}
// 处理按钮
.btn {
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  .el-button {
    font-weight: 900;
  }
}
::v-deep .el-form-item__label {
  padding: 0 0 0 0 !important;
  margin-top: 10px;
}
::v-deep .el-table--mini .el-table__cell {
  padding: 3px 0;
}
::v-deep .el-form-item__content {
    margin-top: 10px;
}
</style>